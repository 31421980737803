<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i> Settings Page
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <b-row>
                    <b-col sm="3">
                      <TextBox v-model="item.dailyGoal" placeholder="Daily Goal Limit">Daily Goal Limit</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.weeklyGoal" placeholder="Weekly Goal Limit">Weekly Goal Limit</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.monthlyGoal" placeholder="Monthly Goal Limit">Monthly Goal Limit</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.yearlyGoal" placeholder="Yearly Goal Limit">Yearly Goal Limit</TextBox>
                    </b-col>
                    <b-col sm="3">
                      <TextBox v-model="item.daysToExpire" placeholder="Days To Expire">Days To Expire</TextBox>
                    </b-col>
                  </b-row>
                  <div class="d-flex justify-content-end">
                    <div class="form-actions">
                      <b-button right type="submit" variant="primary" @click="onSubmit">Save</b-button>
                      <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/dashboard/list'">Cancel</router-link>
                    </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Settings',
    data() {
      return {
        item: {}
      };
    },
    created() {
      this.getSettingsData();
    },
    methods: {
      getSettingsData() {
        this.axios.get('settings/item')
            .then(response => {
              if (response.data.data && response.data.data) {
                this.item = response.data.data;
              }
            })
            .catch(error => {
              if (error.response && error.response.data.status === 400) {
                this.$toastr.error(error.response.data.message);
              }
            });
      },
      onSubmit() {
        this.axios.post('settings/item', this.item)
            .then((res) => {
              if (res.data) {
                this.$toastr.info('Successfully saved')
                this.$router.push('/dashboard/list')
              } else {
                this.$toastr.error(res.data.message)
              }
            })
      }
    },
    components: {}
  };
</script>

<style scoped>

</style>